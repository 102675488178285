import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';

class Home extends Component {
	render() {
		const { main, actionsMain } = this.props
		return (
			<div className="home">
				<div className="container">
					<div className="codm-logo">
						<img src={require('../../assets/img/codm-logo.png')} alt="" />
					</div>
					<div className="barnomor">
						<img src={require('../../assets/img/barnomor.png')} alt="" />
						<div><span id="choosen-name"></span></div>
					</div>
					<div className="btn-group">
						<button onClick={() => main.random_status ? actionsMain.stop_random() : actionsMain.start_random()}><img src={require('../../assets/img/button_start.png')} alt="" /></button>
						<button onClick={() => actionsMain.toggle_modal('winner-list', true)}><img src={require('../../assets/img/button_lihatpemenang.png')} alt="" /></button>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);