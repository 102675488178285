import axios from 'axios';

export const get_login = (data) => ({
	type: 'GET_LOGIN',
	data: data
})

export const toggle_loader = (data) => ({
	type: 'TOGGLE_LOADER',
	data: data
})

export const random_status = data => ({
	type: 'RANDOM_STATUS',
	data
})

export const add_names = data => ({
	type: 'ADD_NAMES',
	data
})

export const get_choosen_name = data => ({
	type: 'GET_CHOOSEN_NAME',
	data
})

export const get_winner_list = data => ({
	type: 'GET_WINNER_LIST',
	data
})

export const toggle_modal = (modal_types, data) => ({
	type: 'TOGGLE_MODAL',
	modal_types,
	data
})

export const login = () => {
	return dispatch => {
		// dispatch(toggle_loader(true))
		// axios.get(process.env.REACT_APP_API_ENDPOINT)
		// 	.then((resp) => {
		// 		dispatch(get_login(resp.data))
		// 		console.log(resp)
		// 	})
		// 	.catch((err) => {
		// 		console.log(err)
		// 	})
		// 	.then(() => {
		// 		dispatch(toggle_loader(false))
		// 	})
	}
}

export const get_names = () => {
	return dispatch => {
		axios
			.get('api/raffle/')
			.then(resp => {
				dispatch(add_names(resp.data.map(el => {
					return el.nickname
				})))
			})
			.catch(err => {
				console.log(err);
			})
		dispatch(winner_list())
	}
}

export const winner_list = () => {
	return dispatch => {
		axios
			.get('api/winner/')
			.then(resp => {
				dispatch(get_winner_list(resp.data))
			})
			.catch(err => {
				console.log(err.response.data);
			})
	}
}

let intervalHandler;
let i;

export const start_random = () => {
	return (dispatch, getState) => {
		const display_name = document.getElementById('choosen-name')
		if (getState().main.random_status) {
			clearInterval(intervalHandler)
		} else {
			intervalHandler = setInterval(() => {
				const names = getState().main.name_list
				const choosen_name = names[Math.floor(Math.random() * names.length)]
				display_name.textContent = choosen_name
				dispatch(get_choosen_name(choosen_name))
			}, 50);
		}
		dispatch(random_status(!getState().main.random_status))
	}
}

export const stop_random = () => {
	return (dispatch, getState) => {
		clearInterval(intervalHandler)
		const names = getState().main.choosen_name
		axios
			.post('api/raffle/', { nickname: names })
			.then(resp => {
				// console.log(resp.data);
			})
			.catch(err => {
				console.log(err);
			})
		dispatch(random_status(!getState().main.random_status))
		dispatch(get_names())
		setTimeout(() => {
			dispatch(winner_list())
		}, 1000)
	}
}