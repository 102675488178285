import React, { Component } from 'react'
import { getUrlParam } from '../helper'

class Auth extends Component {
  componentWillMount() {
    let token = getUrlParam('token')
    if (token) {
      window.localStorage.setItem('token')
    }
    window.location.href = process.env.REACT_APP_BASE_URL
  }

  render() {
    return (<div />);
  }
}

export default Auth;