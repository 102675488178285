const main_state = {
	login: false,
	loader: false,
	random_status: false,
	name_list: false,
	choosen_name: false,
	winner_list: false,
	modal_isopen: false,
	modal_types: ''
}

const main = (state = main_state, action) => {
	switch (action.type) {
		case "GET_LOGIN":
			return {
				...state,
				login: action.data
			};
		case "TOGGLE_LOADER":
			return {
				...state,
				loader: action.data
			}
		case "RANDOM_STATUS":
			return {
				...state,
				random_status: action.data
			}
		case "ADD_NAMES":
			return {
				...state,
				name_list: action.data
			}
		case "GET_CHOOSEN_NAME":
			return {
				...state,
				choosen_name: action.data
			}
		case "GET_WINNER_LIST":
			return {
				...state,
				winner_list: action.data
			}
		case "TOGGLE_MODAL":
			return {
				...state,
				modal_isopen: action.data,
				modal_types: action.modal_types
			}
		default:
			return state;
	}
};

export default main;