import React, { Component } from 'react'

export default class WinnerList extends Component {
  render() {
    const { main, actionsMain } = this.props
    return (
      <div className="popup">
        <img src={require('../../assets/img/popup.jpg')} alt="" />
        <div>
          <div className="top">
            <button onClick={() => actionsMain.toggle_modal(main.modal_types, false)}>
              <img src={require('../../assets/img/btn-close.jpg')} alt="" />
            </button>
            <span>Daftar Pemenang</span>
          </div>
          <ol>
            {this.props.main.winner_list.map((el, key) =>
              <li key={key}>{el.nickname}</li>
            )}
          </ol>
        </div>
      </div>
    )
  }
}
