import React, { Component } from 'react'
import ResponsiveModal from 'react-responsive-modal'

import WinnerList from './WinnerList';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';

class Popup extends Component {
  render() {
    const { main, actionsMain } = this.props
    let popup = ''

    switch (main.modal_types) {
      case 'winner-list':
        popup = <WinnerList {...this.props} />
        break;
      default:
        break;
    }
    return (
      <ResponsiveModal open={main.modal_isopen}
        closeOnOverlayClick={false}
        classNames={{
          modal: 'modal',
          overlay: 'overlay',
          closeButton: 'button'
        }}
        onClose={() => actionsMain.toggle_modal(main.modal_types, false)}>
        <div>{popup}</div>
      </ResponsiveModal>
    )
  }
}

function mapStateToProps(state) {
  return { main: state.main }
}

function mapDispatchToProps(dispatch) {
  return {
    actionsMain: bindActionCreators(mainActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Popup);